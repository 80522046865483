import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {
	const data = useStaticQuery(
		graphql`
			query {
				pageData: wpPage(slug: { eq: "home" }) {
					seoFieldGroups {
						localBusinessSchema
					}
					reviewCountFields {
						googleReviewCount
						bwcReviewCount
						checkatradeReviewCount
					}
				}

				jeffVanImg: file(relativePath: { eq: "jeff-with-van.png" }) {
					publicURL
				}
				schemaLogo: file(relativePath: { eq: "logo.png" }) {
					publicURL
				}
			}
		`
	);

	const reviewCount =
		data.pageData.reviewCountFields.checkatradeReviewCount +
		data.pageData.reviewCountFields.googleReviewCount +
		data.pageData.reviewCountFields.bwcReviewCount;
	const schemaString = data.pageData.seoFieldGroups.localBusinessSchema;

	// Log the schema string to inspect it

	// Parse schema string to JSON
	let localBusinessSchema;
	try {
		localBusinessSchema = JSON.parse(schemaString);
	} catch (e) {
		console.error("Failed to parse local business schema", e);
		return null;
	}

	// Ensure aggregateRating exists before setting reviewCount
	if (localBusinessSchema.aggregateRating) {
		localBusinessSchema.aggregateRating.reviewCount = reviewCount;
	} else {
		localBusinessSchema.aggregateRating = {
			"@type": "AggregateRating",
			bestRating: "5",
			worstRating: "1",
			ratingValue: "4.9",
			reviewCount: reviewCount.toString(),
			url: "https://www.seymour-locksmiths.co.uk/our-locksmith-reviews",
		};
	}

	// Update other dynamic values
	if (data.schemaLogo) {
		localBusinessSchema.logo = data.schemaLogo.publicURL;
	}

	if (data.jeffVanImg) {
		localBusinessSchema.image = data.jeffVanImg.publicURL;
	}

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;
